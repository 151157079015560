import React, { useState } from "react";
import { Helmet } from "react-helmet";

const Post = ({ images, title, description, date }) => {
  const [mainImage, setMainImage] = useState(images[0]);

  const swapImage = (clickedImage) => {
    setMainImage(clickedImage);
  };

  return (
    <div className="w-full md:w-1/2 p-4">
      {/* SEO Meta Tags */}
      <Helmet>
        <title>Prestations - SF Rénovation</title>
        <meta
          name="description"
          content="Retrouvez en images, mon expertise sur mes dernières prestations."
        />
        <meta
          name="keywords"
          content="projets, images, posts, prestations, SF Rénovation"
        />
        <meta name="author" content="SF Rénovation" />
      </Helmet>
      <div className="w-full border-r-8 border-l-8 border-[#1f2937] shadow-md rounded-lg overflow-hidden">
        {/* Texte principal */}
        <div className="p-5 bg-[#1f2937] text-white">
          <h1 className="text-center text-2xl font-extrabold">{title}</h1>
          <p className="mt-2 text-center text-[#e7ab2d] font-semibold">
            {description}
          </p>
          <p className="text-sm mt-1 text-right text-gray-300">
            {new Date(date).toLocaleDateString()}
          </p>
        </div>

        {/* Conteneur d'images */}
        <div className="flex flex-col sm:flex-row h-auto sm:h-[600px]">
          <div className="w-full sm:w-3/4 cursor-pointer bg-[#1f2937]">
            <img
              src={mainImage}
              alt="Grande étape principale"
              className="w-full h-96 sm:h-full object-contain"
            />
          </div>

          <div className="w-full sm:w-1/4 flex flex-row sm:flex-col overflow-x-auto sm:overflow-y-auto h-32 sm:h-full border-t sm:border-l">
            {images
              .filter((img) => img !== mainImage)
              .map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Étape ${index + 2}`}
                  className="w-1/3 sm:w-full h-full sm:h-1/3 object-cover border-r sm:border-b cursor-pointer hover:opacity-80 transition-opacity"
                  onClick={() => swapImage(image)}
                />
              ))}
          </div>
        </div>

        {/* Auteur */}
        <div className="p-4 text-sm text-gray-500 text-right bg-[#1f2937]">
          Posté par SF Rénovation
        </div>
      </div>
    </div>
  );
};

const Projects = () => {
  const postsData = [
    {
      title:
        "Nouvelle réalisation avant cette période de fêtes de fin d’années !",
      description:
        "Rénovation salle de bain complète avec pose d’un parquet vinyle. Un carrelage marbre beige 120/60. Montage des meubles et pose des éléments de salle de bain.",
      date: "2024-12-18",
      images: [
        "../images/post1-1.jpg",
        "../images/post1-2.jpg",
        "../images/post1-3.jpg",
        "../images/post1-4.jpg",
        "../images/post1-5.jpg",
      ],
    },
    {
      title: "Cimentage extérieur",
      description: "Client très satisfait du résultat. Voici les photos.",
      date: "2024-07-24",
      images: [
        "../images/post4-1.jpg",
        "../images/post4-2.jpg",
        "../images/post4-3.jpg",
        "../images/post4-4.jpg",
      ],
    },
    {
      title: "Embellissement d’une cuisine",
      description:
        "Pose de crédence, pose d’une plaque vitrée en dessous de là hôte, modification des prises électriques, installation de levier +plomberie, réalisation d’un nouveau plan de travail.",
      date: "2024-12-01",
      images: [
        "../images/post2-1.jpg",
        "../images/post2-2.jpg",
        "../images/post2-3.jpg",
        "../images/post2-4.jpg",
      ],
    },
    {
      title: "Rénovation Toilette",
      description:
        "Carrelage effet parquet au sol, un carrelage mural en 20x60. Installation d’un toilette + un lave main, modification de la plomberie et la pose du luminaire.",
      date: "2024-12-01",
      images: [
        "../images/post3-1.jpg",
        "../images/post3-2.jpg",
        "../images/post3-3.jpg",
      ],
    },
    {
      title: "Rénovation Salle de bain",
      description:
        "Démontage des anciens meubles, démolition de la cloison de douche, placo, isolation, enduit, peinture, carrelage, montage des meubles et éléments de salle de bain.",
      date: "2025-01-25",
      images: [
        "../images/post5-1.jpg",
        "../images/post5-2.jpg",
        "../images/post5-3.jpg",
        "../images/post5-4.jpg",
        "../images/post5-5.jpg",
        "../images/post5-6.jpg",
        "../images/post5-7.jpg",
      ],
    },
  ];

  const sortedPosts = postsData.sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  return (
    <div className="flex flex-wrap justify-center p-4 bg-gray-100">
      {sortedPosts.map((post, index) => (
        <Post
          key={index}
          title={post.title}
          description={post.description}
          date={post.date}
          images={post.images}
        />
      ))}
    </div>
  );
};

export default Projects;
