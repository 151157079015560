import React, { useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  FaHome,
  FaTools,
  FaProjectDiagram,
  FaInfoCircle,
  FaPhone,
  FaBars,
  FaTimes,
} from "react-icons/fa";

const Home = () => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleSubMenu = () => {
    setIsSubMenuOpen((prev) => !prev);
  };

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
    setIsSubMenuOpen(false); // Ferme le sous-menu quand on ferme le menu principal
  };

  return (
    <div className="relative h-screen bg-gray-900 text-white">
      {/* SEO Meta Tags */}
      <Helmet>
        <title>Accueil - SF Rénovation</title>
        <meta
          name="description"
          content="Bienvenue chez SF Rénovation, spécialiste de la rénovation intérieure et extérieure dans les Hauts-de-France. Nous transformons vos espaces pour qu'ils correspondent parfaitement à vos attentes."
        />
        <meta
          name="keywords"
          content="rénovation intérieure, rénovation extérieure, maçonnerie, Hauts-de-France, SF Rénovation"
        />
        <meta name="author" content="SF Rénovation" />
      </Helmet>
      {/* Background Video */}
      <video
        className="absolute inset-0 object-cover w-full h-full"
        src="/images/presentation.mp4"
        autoPlay
        loop
        muted
      ></video>
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      {/* Header Content */}
      <div className="relative z-10 flex flex-col items-center justify-center h-full text-center px-4">
        <motion.h1
          className="text-6xl font-bold mb-6"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1 }}
        >
          <h1>
            Bienvenue chez{" "}
            <span className="text-yellow-400">SF Rénovation</span>
            <br></br>
            <span className="text-2xl">
              Spécialiste de la rénovation intérieure et extérieure dans les
              <span className="text-yellow-500"> Hauts-de-France</span>
            </span>
          </h1>
        </motion.h1>

        <motion.p
          className="text-xl max-w-2xl mb-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.2 }}
        >
          Nous transformons vos espaces pour qu'ils correspondent parfaitement à
          vos attentes. De la salle de bain au gros œuvre, SF Rénovation est à
          vos côtés !
        </motion.p>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.4 }}
        >
          <Link
            to="/services"
            className="bg-yellow-500 text-white py-3 px-8 rounded-full shadow-lg hover:bg-yellow-400"
          >
            Découvrez nos services
          </Link>
        </motion.div>

        {/* Desktop Navigation Menu */}
        <motion.nav
          className="mt-8 hidden lg:flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.6 }}
        >
          <Link
            to="/"
            className="text-lg text-white hover:text-yellow-400 transition duration-200"
          >
            Accueil
          </Link>

          {/* Services with Sub-Menu */}
          <div className="relative">
            <button
              onClick={toggleSubMenu}
              className="text-lg text-white hover:text-yellow-400 transition duration-200"
            >
              Services
            </button>
            {isSubMenuOpen && (
              <div className="absolute mt-2 bg-gray-700 text-white rounded shadow-lg">
                <Link
                  to="/services/interieur"
                  className="block px-4 py-2 hover:bg-gray-600"
                >
                  Rénovation intérieur
                </Link>
                <Link
                  to="/services/exterieur"
                  className="block px-4 py-2 hover:bg-gray-600"
                >
                  Rénovation extérieur
                </Link>
                <Link
                  to="/services/maconnerie"
                  className="block px-4 py-2 hover:bg-gray-600"
                >
                  Maçonnerie
                </Link>
              </div>
            )}
          </div>

          <Link
            to="/projects"
            className="text-lg text-white hover:text-yellow-400 transition duration-200"
          >
            Réalisations
          </Link>
          <Link
            to="/about"
            className="text-lg text-white hover:text-yellow-400 transition duration-200"
          >
            À propos
          </Link>
          <Link
            to="/contact"
            className="text-lg text-white hover:text-yellow-400 transition duration-200"
          >
            Contact
          </Link>
        </motion.nav>

        {/* Mobile Menu Button */}
        <button
          className="lg:hidden fixed top-4 right-4 z-20 text-white focus:outline-none"
          onClick={toggleMenu}
        >
          {isMenuOpen ? (
            <FaTimes className="w-8 h-8" />
          ) : (
            <FaBars className="w-8 h-8" />
          )}
        </button>

        {/* Mobile Navigation Menu */}
        {isMenuOpen && (
          <motion.div
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            transition={{ duration: 0.3 }}
            className="fixed top-0 right-0 w-3/4 h-full bg-gray-800 text-white z-30 flex flex-col items-center justify-center space-y-8 p-4 shadow-lg"
          >
            <button
              onClick={toggleMenu}
              className="absolute top-4 right-4 text-white text-2xl"
            >
              <FaTimes className="w-8 h-8" />
            </button>

            <Link
              to="/"
              className="flex items-center text-lg text-yellow-400 font-semibold hover:text-yellow-500"
              onClick={toggleMenu}
            >
              <FaHome className="mr-3" /> Accueil
            </Link>

            {/* Services with Sub-Menu */}
            <button
              onClick={toggleSubMenu}
              className="flex items-center text-lg text-yellow-400 font-semibold hover:text-yellow-500"
            >
              <FaTools className="mr-3" /> Services
            </button>
            {isSubMenuOpen && (
              <div className="flex flex-col items-start pl-6 space-y-2">
                <Link
                  to="/services/interieur"
                  className="text-sm text-white hover:text-yellow-400"
                  onClick={toggleMenu}
                >
                  Rénovation intérieur
                </Link>
                <Link
                  to="/services/exterieur"
                  className="text-sm text-white hover:text-yellow-400"
                  onClick={toggleMenu}
                >
                  Rénovation extérieur
                </Link>
                <Link
                  to="/services/maconnerie"
                  className="text-sm text-white hover:text-yellow-400"
                  onClick={toggleMenu}
                >
                  Maçonnerie
                </Link>
              </div>
            )}

            <Link
              to="/projects"
              className="flex items-center text-lg text-yellow-400 font-semibold hover:text-yellow-500"
              onClick={toggleMenu}
            >
              <FaProjectDiagram className="mr-3" /> Réalisations
            </Link>
            <Link
              to="/about"
              className="flex items-center text-lg text-yellow-400 font-semibold hover:text-yellow-500"
              onClick={toggleMenu}
            >
              <FaInfoCircle className="mr-3" /> À propos
            </Link>
            <Link
              to="/contact"
              className="flex items-center text-lg text-yellow-400 font-semibold hover:text-yellow-500"
              onClick={toggleMenu}
            >
              <FaPhone className="mr-3" /> Contact
            </Link>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default Home;
