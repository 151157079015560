import React, { useState } from "react";
import emailjs from "emailjs-com";
import { FaFacebookF } from "react-icons/fa";
import { Helmet } from "react-helmet";

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    subject: "contact",
    message: "",
    notRobot: false,
  });

  const [errors, setErrors] = useState({});
  const [isSending, setIsSending] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName.trim())
      newErrors.firstName = "Le prénom est requis.";
    if (!formData.lastName.trim()) newErrors.lastName = "Le nom est requis.";
    if (!formData.email.trim() || !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Une adresse email valide est requise.";
    }
    if (!formData.phone.trim() || !/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Un numéro de téléphone valide est requis.";
    }
    if (!formData.message.trim())
      newErrors.message = "Le message ne peut pas être vide.";
    if (!formData.notRobot)
      newErrors.notRobot = "Veuillez confirmer que vous n'êtes pas un robot.";
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setErrors({});
    setIsSending(true);
    setSuccessMessage("");
    setErrorMessage("");

    // Préparer les données pour EmailJS
    const templateParams = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phone: formData.phone,
      subject: formData.subject,
      message: formData.message,
    };

    // Envoyer les données via EmailJS
    emailjs
      .send(
        "service_zgbxcif", // Remplacez par votre Service ID
        "template_7xq9ij9", // Remplacez par votre Template ID
        templateParams,
        "t_xW6NxvZYG4LhijH" // Remplacez par votre Public Key
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setIsSending(false);
          setSuccessMessage("Votre message a été envoyé avec succès !");
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            subject: "contact",
            message: "",
            notRobot: false,
          });
        },
        (err) => {
          console.error("FAILED...", err);
          setIsSending(false);
          setErrorMessage("Une erreur est survenue. Veuillez réessayer.");
        }
      );
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900 relative overflow-hidden p-6">
      {/* SEO Meta Tags */}
      <Helmet>
        <title>Contact - SF Rénovation</title>
        <meta
          name="description"
          content="Vous avez des questions sur mes prestations ? Vous avez besoin d'un devis détaillé ? contactez moi et je vous répond sous 48heures."
        />
        <meta
          name="keywords"
          content="SF Rénovation, contact, devis, question, réponse, 48heures, rapide, prestations, Nord"
        />
        <meta name="author" content="SF Rénovation" />
      </Helmet>
      <div className="bg-gray-800 shadow-lg rounded-lg p-10 max-w-5xl w-full">
        <h1 className="text-3xl font-bold text-center text-yellow-400 mb-4">
          Un devis ? Une question ?
        </h1>
        <p className="text-center text-gray-300 mb-6">
          Je vous répond sous 48heures !
        </p>

        <div className="md:flex md:space-x-10">
          {/* Section Formulaire */}
          <div className="md:w-2/3">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="flex gap-4">
                <div className="w-1/2">
                  <input
                    type="text"
                    name="firstName"
                    placeholder="Prénom"
                    value={formData.firstName}
                    onChange={handleChange}
                    className="w-full p-3 bg-gray-700 text-gray-200 border border-gray-600 rounded focus:ring focus:ring-yellow-400"
                  />
                  {errors.firstName && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.firstName}
                    </p>
                  )}
                </div>
                <div className="w-1/2">
                  <input
                    type="text"
                    name="lastName"
                    placeholder="Nom"
                    value={formData.lastName}
                    onChange={handleChange}
                    className="w-full p-3 bg-gray-700 text-gray-200 border border-gray-600 rounded focus:ring focus:ring-yellow-400"
                  />
                  {errors.lastName && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.lastName}
                    </p>
                  )}
                </div>
              </div>

              <div>
                <input
                  type="email"
                  name="email"
                  placeholder="Votre Email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full p-3 bg-gray-700 text-gray-200 border border-gray-600 rounded focus:ring focus:ring-yellow-400"
                />
                {errors.email && (
                  <p className="text-red-500 text-sm mt-1">{errors.email}</p>
                )}
              </div>

              <div>
                <input
                  type="tel"
                  name="phone"
                  placeholder="Téléphone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="w-full p-3 bg-gray-700 text-gray-200 border border-gray-600 rounded focus:ring focus:ring-yellow-400"
                />
                {errors.phone && (
                  <p className="text-red-500 text-sm mt-1">{errors.phone}</p>
                )}
              </div>

              <div>
                <select
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  className="w-full p-3 bg-gray-700 text-gray-200 border border-gray-600 rounded focus:ring focus:ring-yellow-400"
                >
                  <option value="contact">Contact</option>
                  <option value="devis">Devis</option>
                </select>
              </div>

              <div>
                <textarea
                  name="message"
                  placeholder="Votre Message"
                  value={formData.message}
                  onChange={handleChange}
                  className="w-full p-3 bg-gray-700 text-gray-200 border border-gray-600 rounded focus:ring focus:ring-yellow-400"
                  rows="4"
                ></textarea>
                {errors.message && (
                  <p className="text-red-500 text-sm mt-1">{errors.message}</p>
                )}
              </div>

              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  id="not-robot"
                  name="notRobot"
                  checked={formData.notRobot}
                  onChange={handleChange}
                />
                <label htmlFor="not-robot" className="text-gray-300">
                  Je ne suis pas un robot
                </label>
              </div>
              {errors.notRobot && (
                <p className="text-red-500 text-sm">{errors.notRobot}</p>
              )}

              <button
                type="submit"
                className="w-full bg-yellow-400 text-gray-900 px-6 py-3 font-semibold rounded-md hover:bg-yellow-300 transition"
                disabled={isSending}
              >
                {isSending ? "Envoi en cours..." : "Envoyer"}
              </button>
            </form>

            {successMessage && (
              <p className="text-green-500 text-center mt-4">
                {successMessage}
              </p>
            )}
            {errorMessage && (
              <p className="text-red-500 text-center mt-4">{errorMessage}</p>
            )}
          </div>

          {/* Section Informations de contact */}
          <div className="mt-10 md:mt-0 md:w-1/3 bg-gray-700 p-6 rounded-lg text-gray-200 border border-gray-600 space-y-4">
            <h2 className="text-xl font-semibold text-yellow-400">
              Informations de contact
            </h2>
            <p>
              <span className="font-bold text-yellow-300">Mail : </span>
              fabien.spada@hotmail.com
            </p>
            <p>
              <span className="font-bold text-yellow-300">Téléphone : </span>
              06.70.77.77.29
            </p>
            <p>
              <span className="font-bold text-yellow-300">Adresse : </span>
              Wattrelos
            </p>
            <p>
              <span className="font-bold text-yellow-300">
                Réseaux sociaux :{" "}
              </span>
              <a
                href="https://www.facebook.com/sf.renovation.2024"
                target="_blank"
                rel="noopener noreferrer"
                className="text-yellow-200 hover:text-yellow-100 inline-flex items-center gap-2"
              >
                <FaFacebookF />
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
