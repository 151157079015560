import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { FaTools, FaShieldAlt, FaLayerGroup } from "react-icons/fa";
import { Helmet } from "react-helmet";

const MasonryDetails = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const reasons = [
    {
      title: "Matériaux de Qualité",
      description:
        "Nous utilisons uniquement des matériaux fiables pour des résultats durables.",
      image: "/images/exterior3.jpg",
      icon: <FaTools size={40} className="text-yellow-500 mb-4" />,
    },
    {
      title: "Professionnalisme",
      description:
        "Une équipe expérimentée et dédiée à fournir un travail irréprochable.",
      image: "/images/masonry2.jpg",
      icon: <FaShieldAlt size={40} className="text-yellow-500 mb-4" />,
    },
    {
      title: "Satisfaction Client",
      description:
        "Votre satisfaction est notre priorité absolue, du début à la fin.",
      image: "/images/masonry4.jpg",
      icon: <FaLayerGroup size={40} className="text-yellow-500 mb-4" />,
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % reasons.length);
    }, 4000);
    return () => clearInterval(interval);
  }, [reasons.length]);

  return (
    <motion.section
      className="bg-gray-50 text-gray-900 min-h-screen py-16"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <div className="container mx-auto px-6 lg:px-16">
        {/* SEO Meta Tags */}
        <Helmet>
          <title>Maçonnerie - SF Rénovation</title>
          <meta
            name="description"
            content="Bienvenue chez SF Rénovation, expert maçon dans les Hauts-de-France."
          />
          <meta
            name="keywords"
            content="maçon, rénovation, maçonnerie, Hauts-de-France, Nord, SF Rénovation"
          />
          <meta name="author" content="SF Rénovation" />
        </Helmet>
        {/* Header Section */}
        <motion.div
          className="text-center mb-16"
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <h1 className="text-4xl lg:text-5xl font-bold mb-4">
            Expertise en Maçonnerie et Rénovation
          </h1>
          <p className="text-lg lg:text-xl max-w-3xl mx-auto">
            Alliez durabilité, esthétique et innovation dans vos projets de
            rénovation. Nous offrons des services adaptés à vos besoins, en
            mettant en avant des matériaux de qualité et une exécution soignée.
          </p>
        </motion.div>

        {/* Section "Pourquoi Nous Choisir" avec Images et Carousel */}
        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 mb-16"
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          {reasons.map((reason, index) => (
            <motion.div
              key={index}
              className={`flex flex-col items-center text-center p-6 rounded-lg shadow-lg ${
                currentIndex === index
                  ? "border-2 border-yellow-500 scale-105"
                  : ""
              } transition-all duration-300`}
            >
              <div className="w-full h-72 mb-4 overflow-hidden rounded-lg">
                <img
                  src={reason.image}
                  alt={reason.title}
                  className="w-full h-full object-cover"
                />
              </div>
              {reason.icon}
              <h3 className="text-xl font-semibold mb-3">{reason.title}</h3>
              <p>{reason.description}</p>
            </motion.div>
          ))}
        </motion.div>

        {/* Appel à l'Action */}
        <motion.div
          className="text-center"
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <h1 className="text-2xl font-bold mb-6">
            Contactez SF Rénovation pour votre projet
          </h1>
          <p className="text-lg max-w-2xl mx-auto mb-8">
            Expert en maçonnerie et rénovation disponibles pour transformer vos
            espaces. Prenez rendez-vous dès aujourd'hui.
          </p>
          <a
            href="/contact"
            className="px-8 py-4 bg-yellow-500 text-white font-semibold text-lg rounded-full shadow-lg hover:bg-yellow-600 transition-all duration-300"
          >
            Demander un devis
          </a>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default MasonryDetails;
