import React from "react";
import Slider from "react-slick";
import { motion } from "framer-motion";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from "react-helmet";

const InteriorDetails = () => {
  const images = [
    "../images/inter1.jpg",
    "../images/inter2.jpg",
    "../images/inter3.jpg",
    "../images/inter4.jpg",
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    pauseOnHover: true,
  };

  return (
    <motion.section
      className="py-20 bg-gradient-to-br from-gray-50 via-white to-gray-100"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <div className="container mx-auto px-6 lg:px-20">
        {/* SEO Meta Tags */}
        <Helmet>
          <title>Rénovations intérieures - SF Rénovation</title>
          <meta
            name="description"
            content="SF Rénovation, spécialiste de la rénovation intérieure dans les Hauts-de-France. Nous transformons vos espaces pour qu'ils correspondent parfaitement à vos attentes."
          />
          <meta
            name="keywords"
            content="rénovation intérieure, Hauts-de-France, Nord, SF Rénovation, salle de bains, carrelage, platerie, placo, expert, matériaux, transformation"
          />
          <meta name="author" content="SF Rénovation" />
        </Helmet>
        {/* Titre principal */}
        <motion.div
          className="text-center mb-16"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h1 className="text-5xl font-extrabold text-gray-900 leading-tight mb-4">
            Rénovation et Finitions Intérieures
          </h1>
          <p className="text-lg text-gray-600 max-w-3xl mx-auto leading-relaxed">
            Offrez à vos espaces intérieurs un renouveau élégant et fonctionnel.
            Découvrez notre expertise en aménagement sur mesure.
          </p>
        </motion.div>

        {/* Carrousel avec animation */}
        <motion.div
          className="mb-16"
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <div className="max-w-md mx-auto">
            {" "}
            {/* Limite la largeur à 768px */}
            <Slider {...sliderSettings}>
              {images.map((src, index) => (
                <div key={index} className="px-4">
                  <div
                    className="relative w-full overflow-hidden rounded-3xl shadow-lg"
                    style={{
                      paddingTop: "133.33%", // Ratio 3:4
                    }}
                  >
                    <img
                      src={src}
                      alt={`Interior example ${index + 1}`}
                      className="absolute top-0 left-0 w-full h-full object-cover"
                    />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </motion.div>

        {/* Services détaillés */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          {/* Liste des services */}
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            <h1 className="text-3xl font-semibold text-gray-800 mb-6 text-center sm:text-left">
              Mes prestations
            </h1>
            <ul className="space-y-6">
              <li className="flex flex-col sm:flex-row items-start">
                <span className="w-8 h-8 bg-yellow-500 rounded-full flex items-center justify-center text-white font-bold mr-4 mb-4 sm:mb-0">
                  1
                </span>
                <div>
                  <h1 className="text-xl font-bold text-gray-800">
                    Rénovation de salles de bains
                  </h1>
                  <p className="text-gray-600 text-sm sm:text-base">
                    Designs modernes, équipements performants et matériaux
                    premium pour transformer vos espaces.
                  </p>
                </div>
              </li>
              <li className="flex flex-col sm:flex-row items-start">
                <span className="w-8 h-8 bg-yellow-500 rounded-full flex items-center justify-center text-white font-bold mr-4 mb-4 sm:mb-0">
                  2
                </span>
                <div>
                  <h1 className="text-xl font-bold text-gray-800">
                    Pose de carrelage
                  </h1>
                  <p className="text-gray-600 text-sm sm:text-base">
                    Installation de carrelages variés pour un style unique et
                    durable.
                  </p>
                </div>
              </li>
              <li className="flex flex-col sm:flex-row items-start">
                <span className="w-8 h-8 bg-yellow-500 rounded-full flex items-center justify-center text-white font-bold mr-4 mb-4 sm:mb-0">
                  3
                </span>
                <div>
                  <h1 className="text-xl font-bold text-gray-800">
                    Cloisons en placo
                  </h1>
                  <p className="text-gray-600 text-sm sm:text-base">
                    Séparez vos espaces avec des solutions esthétiques et
                    isolantes.
                  </p>
                </div>
              </li>
            </ul>
          </motion.div>

          {/* Bloc "Pourquoi nous choisir ?" */}
          <motion.div
            className="bg-white rounded-3xl shadow-lg p-6 sm:p-8 max-w-lg sm:max-w-2xl mx-auto"
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            <h2 className="text-2xl sm:text-3xl font-semibold text-gray-800 mb-6 text-center sm:text-left">
              Pourquoi nous choisir ?
            </h2>
            <ul className="space-y-4 sm:space-y-6">
              <li className="flex flex-col sm:flex-row items-start">
                <span className="w-8 h-8 bg-yellow-500 rounded-full flex items-center justify-center text-white font-bold mr-0 sm:mr-4 mb-4 sm:mb-0">
                  ✓
                </span>
                <p className="text-gray-600 text-sm sm:text-base text-center sm:text-left">
                  Expertise et savoir-faire pour des projets à la hauteur de vos
                  attentes.
                </p>
              </li>
              <li className="flex flex-col sm:flex-row items-start">
                <span className="w-8 h-8 bg-yellow-500 rounded-full flex items-center justify-center text-white font-bold mr-0 sm:mr-4 mb-4 sm:mb-0">
                  ✓
                </span>
                <p className="text-gray-600 text-sm sm:text-base text-center sm:text-left">
                  Matériaux haut de gamme pour une durabilité garantie.
                </p>
              </li>
              <li className="flex flex-col sm:flex-row items-start">
                <span className="w-8 h-8 bg-yellow-500 rounded-full flex items-center justify-center text-white font-bold mr-0 sm:mr-4 mb-4 sm:mb-0">
                  ✓
                </span>
                <p className="text-gray-600 text-sm sm:text-base text-center sm:text-left">
                  Accompagnement personnalisé pour répondre à vos besoins.
                </p>
              </li>
            </ul>
          </motion.div>
        </div>

        {/* Appel à l'action */}
        <motion.div
          className="mt-16 text-center"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <h2 className="text-3xl font-semibold text-gray-800 mb-4">
            Prêt à transformer vos espaces ?
          </h2>
          <p className="text-gray-600 max-w-2xl mx-auto mb-6">
            Contactez-nous dès aujourd’hui pour discuter de vos projets et
            obtenir un devis personnalisé.
          </p>
          <a
            href="/contact"
            className="px-8 py-4 bg-yellow-500 text-white font-bold text-lg rounded-full shadow-md hover:bg-yellow-600 transition-all duration-300"
          >
            Demander un devis
          </a>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default InteriorDetails;
